import React from "react";
import Helmet from "react-helmet";
import { RoutePath } from "../../../route-paths";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import CollapsibleBlocksWrapper from "../../components/collapsible-block/collapsible-blocks-wrapper";
import Container from "../../components/container/container";
import MainHeader from "../../components/main-header/main-header";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import StandardTextBlock from "../../components/standard-text-block/standard-text-block";
import TopLink from "../../components/top-link/top-link";
import "./specialist-clinics-page.scss";
import { oculist, orthodontist, gynecologist, neurologist, dentist, psychologist, rheumatologist, dermatologist } from "./specialist-clinics-data";
import DoctorRow from "../../components/row-info/doctors-row";

const SpecialistClinicsPage: React.FC<any> = () => {

  return (
    <PageWrapper className="specialist-clinics-page">
      <Helmet>
        <title>{'Poradnie specjalistyczne | SPZOZ Przychodnia Syców'}</title>
      </Helmet>

      <section className="pageWrapper__section">
        <Container>
          <MainHeader>PORADNIE SPECJALISTYCZNE</MainHeader>
          <CollapsibleBlocksWrapper>
            <CollapsibleBlock
              title="OKULISTA"
              isSplited={false}
            >
              {oculist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <StandardTextBlock>
              <br /><p>Poradnia okulistyczna- rejestracja do poradni pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 5145</a>, <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 5192</a>, <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a></p><br />
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="ORTODONTA"
              isSplited={false}
            >
              {orthodontist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <br /><p>Poradnia ortodontyczna  - rejestracja do poradni w dniach: poniedziałek i środa 15:00 – 18:00 oraz wtorek, czwartek i piątek 8:00 – 11:00, pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 5190" href="tel:627855190">62 785 5190</a></p><br />
            </CollapsibleBlock>
            <CollapsibleBlock
              title="GINEKOLOG"
              isSplited={false}
            >
              {gynecologist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <br />
              <StandardTextBlock>
              <p><strong>UMÓW WIZYTĘ ONLINE</strong></p>
                <p><a aria-label="link do erejestracja mmedica" href="https://przychodnia.erejestracja.eu">eRejestracja mMedica</a></p>
              </StandardTextBlock>
              <br />
              <StandardTextBlock>
                <p><strong>REJESTRACJA</strong></p>
                <p><strong>DO PORADNI GINEKOLOGICZNO POŁOŻNICZEJ</strong></p>
                <p><a aria-label="Pod numerem telefonu 62 785 51 47" href="tel:627855147">62 785 51 47</a></p>
                <ul>
                  <li>poniedziałek od godz. 08:00 do 14:00</li>
                  <li>wtorek od godz. 11:00 do 18:00</li>
                  <li>środa: NIECZYNNE</li>
                  <li>czwartek od godz. 14:00 do 18:00</li>
                  <li>piątek od godz. 08:00 do 13:00</li>
                </ul>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="NEUROLOG"
              isSplited={false}
            >
              {neurologist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <br /><p>Poradnia neurologiczna – rejestracja do poradni pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 5145</a>, <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 5192</a>, <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a></p><br />
            </CollapsibleBlock>
            <CollapsibleBlock
              title="STOMATOLOG"
              isSplited={false}
            >
               {dentist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
               <br /><p>Poradnia stomatologiczna – rejestracja do poradni w dniach: poniedziałek, wtorek, środa, piątek 7:00 – 10:00 oraz w czwartek 11:00 – 14:00 pod nr telefonu: <a aria-label="Pod numerem telefonu 62 606 6982" href="tel:626066982">62 606 6982</a></p><br />
            </CollapsibleBlock>
            <CollapsibleBlock
              title="PSYCHOLOG/PSYCHIATRA"
              isSplited={false}
            >
              {psychologist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <br />
              <StandardTextBlock>
              <br /><p>Poradnia zdrowia psychicznego – rejestracja do poradni pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 5196" href="tel:627855196">62 785 5196</a></p><br />
                <p><strong>UMÓW WIZYTĘ ONLINE</strong></p>
                <p><a aria-label="link do erejestracja mmedica" href="https://przychodnia.erejestracja.eu">eRejestracja mMedica</a></p>
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="REUMATOLOG"
              isSplited={false}
            >
               {rheumatologist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
               <br /><p>Poradnia reumatologiczna - rejestracja do poradni pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 51 45" href="tel:627855145">62 785 5145</a>, <a aria-label="Pod numerem telefonu 62 785 51 92" href="tel:627855192">62 785 51 92</a>, <a aria-label="Pod numerem telefonu 503 622 361" href="tel:503622361">503 622 361</a></p><br />
            </CollapsibleBlock>
            <CollapsibleBlock
              title="DERMATOLOG"
              isSplited={false}
            >
               {dermatologist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
              <StandardTextBlock>
              <br /><p>Przyjęcia odbywają się na podstawie rejestracji poradni dermatologicznej.<br />Rejestracja odbywa się na podstawie skierowania od lekarza pierwszego kontaktu telefonicznie pod nr telefonu: <a aria-label="Pod numerem telefonu 62 785 51 86"href="tel:627855186">627855186</a></p><br />
              </StandardTextBlock>
            </CollapsibleBlock>
            <CollapsibleBlock
              title="MEDYCYNA PRACY"
              isSplited={false}
            >
              <StandardTextBlock>
                <p><strong>REJESTRACJA PACJENTÓW:</strong></p>
                <p>Poniedziałek: 12.00 - 14.00<br />
                Środa: 7.30 - 14.00<br />
                Piątek 8:00 – 12:00</p>
                <p>Telefon do rejestracji; <a aria-label="Pod numerem telefonu 519 803 506" href="tel:519803506">519 803 506</a></p>
                <br />
                <p>Uprzejmie informujemy, że w związku z projektem Rozporządzenia Ministra Zdrowia od kwietnia 2015 r. obowiązuje nowy wzór skierowania na badania profilaktyczne. Wzory skierowań do pobrania:</p>
                <p><a aria-label="PDF 224KB dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/Skierowanie-na-badanie-lekarskie.pdf">Skierowanie na badanie lekarskie (PDF 224KN)</a></p>
                <p><a aria-label="PDF 494 dokument otworzy się w nowym oknie" target="_blank" href="./do-pobrania/skierowanie-na-badania-sanitarno-epidemiologiczne.pdf">Skierowanie na badania sanitarno-epidemiologiczne (PDF 494)</a></p>
                {/*             TODO LINK NIE DZIAŁA BO NIE MA PDF'A    
                <p><a target="_blank" href="./do-pobrania/Skierowanie-na-badanie-psychologiczne-dla-kierowców-zawodowych.pdf" rel="">Skierowanie na badanie psychologiczne dla kierowców zawodowych</a></p>  */}
                <br />
                <p>Osoby przyjmowane do pracy u nowego pracodawcy nie muszą wykonywać wstępnych badań lekarskich, jeśli posiadają aktualne orzeczenie uzyskane na podstawie skierowania wydanego przez poprzedniego pracodawcę (orzeczenie musi stwierdzać brak przeciwwskazań do pracy w warunkach odpowiadających warunkom występującym na nowym stanowisku pracy).</p>
                <br />
                <p style={{ textAlign: 'center' }}>ROZPORZĄDZENIE MINISTRA ZDROWIA</p>
                <br />
                <p style={{ textAlign: 'center' }}>z dnia 26 sierpnia 2014 r.</p>
                <br />
                <p style={{ textAlign: 'center' }}>w sprawie badań lekarskich kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów, słuchaczy kwalifikacyjnych kursów zawodowych oraz uczestników studiów doktoranckich</p>
                <p style={{ textAlign: 'center' }}>(Dz. U. z dnia 28 sierpnia 2014 r.) &nbsp; &nbsp;<span><TopLink to={RoutePath.ROZPOZADZENIEMINISTRAZDROWIAPAGE}>więcej</TopLink></span></p>
                <br />
                <p style={{ textAlign: 'center' }}>—————————————————————</p>
                <br />
                <p style={{ textAlign: 'justify' }}>Wzór skierowania na badanie lekarskie dla kandydatów do szkół ponadgimnazjalnych lub wyższych i na kwalifikacyjne kursy zawodowe, uczniów tych szkół, studentów, słuchaczy kwalifikacyjnych kursów zawodowych oraz uczestników studiów doktoranckich &nbsp;&nbsp;<span><a aria-label="PDF 1331 KB dokument otworzy się w nowym oknie" href="./do-pobrania/Skierowanie.pdf" target="_blank">do pobrania</a></span></p>
                <br />
                <br />
                <br />
              </StandardTextBlock>
              {oculist.map((doctor,index)=><DoctorRow key={index} name={doctor.name} specializations={doctor.specializations}/>)}
            </CollapsibleBlock>
          </CollapsibleBlocksWrapper>
        </Container>
      </section>
    </PageWrapper>
  )
}

export default SpecialistClinicsPage;